// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginService } from '../../app/main/pages/authentication/login/login.service';

// @ 클래스 설정 ------------------------------------------------------------------------------------------
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    
    constructor(
        private _location: Location,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private _loginService: LoginService,
        private _httpClient: HttpClient,

        ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Methods
    // -----------------------------------------------------------------------------------------------------

    // login(membername: string, password: string) {
    //     return this.http.post<any>(`${environment.apiUrl}/members/authenticate`, { membername, password })
    //         .pipe(map(member => {
    //             // store member details and jwt token in local storage to keep member logged in between page refreshes
    //             localStorage.setItem('member', JSON.stringify(member));
    //             this.  .next(member);
    //             return member;
    //         }));
    // }

    logout(isMessage) {

        const sns_type = localStorage.getItem('sns_type');

        if (sns_type === 'kakao') {
            console.log("kakao logout!!");

            const token = localStorage.getItem('kakao_token');
            this._httpClient.get('https://4mul-api.com/kakao-logout/' + token)
            .subscribe((response: any) => {
            });
   
        } else if (sns_type === 'naver') {
            console.log("naver logout!!");
        } else if (sns_type === 'facebook') {
            console.log("facebook logout!!");

            window['FB'].logout(function(response) {
                // Person is now logged out
             });
    
        }

        if(isMessage) {
            // 메시지 표시
            this._matSnackBar.open('로그아웃 되었습니다.', 'OK', {
                verticalPosition: 'top',
                duration        : 2000
            });
            
        } 

        // 쿠키, 스토리지 삭제
        localStorage.removeItem('token');
        localStorage.removeItem('member');
        localStorage.removeItem('sns_type');
        localStorage.removeItem('kakao_token');
        localStorage.removeItem('naver_token');

        // 구독 삭제
        this._loginService.onCurrentMemberDataChanged.next(null);

        // 이동 
        this._location.go('');
        this._router.navigate(['']);
        
    }
}