// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { InMemoryDbService } from 'angular-in-memory-web-api';

import { CompensationFakeDb } from 'app/fake-db/compensation';
import { HospitalFakeDb } from 'app/fake-db/fake-hospital';

import { ProjectDashboardDb } from 'app/fake-db/dashboard-project';
import { AnalyticsDashboardDb } from 'app/fake-db/dashboard-analytics';
import { CalendarFakeDb } from 'app/fake-db/calendar';
import { AcademyFakeDb } from 'app/fake-db/academy';

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class FakeDbService implements InMemoryDbService {
    createDb(): any {
        return {

            // Compensation
            'compensation-compensations'  : CompensationFakeDb.compensations,
            'compensation-filters': CompensationFakeDb.filters,
            'compensation-tags'   : CompensationFakeDb.tags,
            'compensation-categories'   : CompensationFakeDb.categories,

            // Hospital
            'hospital-filters': HospitalFakeDb.filters,
            'hospital-tags'   : HospitalFakeDb.tags,
            'hospital-categories'   : HospitalFakeDb.categories,
                        
            // Dashboards
            'project-dashboard-projects' : ProjectDashboardDb.projects,
            'project-dashboard-widgets'  : ProjectDashboardDb.widgets,
            'analytics-dashboard-widgets': AnalyticsDashboardDb.widgets,

            // Calendar
            'calendar': CalendarFakeDb.data,

            // Academy
            'academy-categories': AcademyFakeDb.categories,
            'academy-courses'   : AcademyFakeDb.courses,
            'academy-course'    : AcademyFakeDb.course,

        };
    }
}
