<mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

        <!-- --------------------------------------------------------------------------------------------- -->
        <!-- @ 수정해야 할 항목 %%MODIFY%%                                                                     -->
        <!-- --------------------------------------------------------------------------------------------- -->

        <span class="mat-caption">© Copyright 2020 SAMUL. All rights reserved.</span>

        <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <span>&bull;</span>
            <a mat-button href="https://center-pf.kakao.com/" target="_blank">카톡 플러스친구</a>
            <span>&bull;</span>
            <a mat-button href="https://www.youtube.com/" target="_blank">사물 유튜브</a>
            <span>&bull;</span>
            <a mat-button href="https://docs.google.com/document/d/1y0yPCF9rvP4IamP7GiEwuhdFofS-RBroIqhRJFd5ZKk/edit?usp=sharing" target="_blank">사용자 매뉴얼</a>
            <span>&bull;</span>
            <a mat-button href="https://docs.google.com/spreadsheets/d/13tae4IXeyXfI97oNHExu2Y1w46LKor7OraiblACTM9A/edit?usp=sharing" target="_blank">이슈 노트</a>
        </div>

        <!-- --------------------------------------------------------------------------------------------- -->

    </div>

</mat-toolbar>