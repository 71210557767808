// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DreampackersConfigService } from '@dreampackers/services/config.service';
import { navigation } from 'app/navigation/navigation';

// @ 데코레이터 설정 ----------------------------------------------------------------------------------------
@Component({
    selector     : 'vertical-layout-3',
    templateUrl  : './layout-3.component.html',
    styleUrls    : ['./layout-3.component.scss'],
    encapsulation: ViewEncapsulation.None
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class VerticalLayout3Component implements OnInit, OnDestroy{
    dreampackersConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _dreampackersConfigService: DreampackersConfigService
    ){
        // 기본 설정
        this.navigation = navigation;

        // private 기본 설정
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle 
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        // 설정 변경 구독
        this._dreampackersConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.dreampackersConfig = config;
            });
    }

    ngOnDestroy(): void {
        // 모든 구독에서 탈퇴
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
