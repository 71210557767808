/*`fileReplacements` 배열을 사용하여 빌드하는 동안이 파일을 교체 할 수 있습니다.
 *`ng build --prod`는`environment.ts`를`environment.prod.ts`로 대체합니다.
 * 파일 교체 목록은`angular.json`에서 찾을 수 있습니다.
 */

export const environment = {
    production: false,
    hmr       : false
};

/*
 * 개발 모드에서 쉽게 디버깅하기 위해 다음 파일을 가져올 수 있습니다
  `zone.run`,`zoneDelegate.invokeTask`와 같은 영역 관련 오류 스택 프레임을 무시합니다.
 *
 * 이 가져오기는 부정적인 영향을 미치므로 생산 모드에서 주석 처리해야합니다.
 * 오류가 발생하면 성능이 저하됩니다.
 */
// import 'zone.js / dist / zone-error'; // Angular CLI에 포함되어 있습니다.
