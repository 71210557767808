// %%MODIFY%%
export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'COMPENSATION' : 'COMPENSATION',
            'HOSPITAL'     : 'HOSPITAL',
            'EXPERT'       : 'EXPERT',
            'MEMBER'       : 'MEMBER',
            'REVIEW'       : 'REVIEW',
            'NOTICE'       : 'NOTICE',
            'FAQ'          : 'FAQ',
            'BASIC_SETUP'  : 'BASIC_SETUP',
            'CALCULATOR'   : 'CALCULATOR',
            'REG_COMPENSATION' : 'REG_COMPENSATION',
            'CHANGELOG'    : 'CHANGELOG',

            'APPLICATIONS' : 'APPLICATIONS',
            'PAGES'        : 'PAGES',
            'DOCUMENTATION': 'DOCUMENTATION'
        }
    }
};
