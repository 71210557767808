// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Component, ElementRef, Input, Renderer2, ViewEncapsulation } from '@angular/core';


// @ 데코레이터 설정 ---------------------------------------------------------------------------------------
@Component({
    selector     : 'navbar',
    templateUrl  : './navbar.component.html',
    styleUrls    : ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class NavbarComponent{
    // Private
    _variant: string;

    constructor(
        private _elementRef: ElementRef,
        private _renderer: Renderer2
    ){
        // 개인 기본값 설정
        this._variant = 'vertical-style-1';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get variant(): string{
        return this._variant;
    }

    @Input()
    set variant(value: string)
    {
        // 이전 클래스 이름을 제거하십시오.
        this._renderer.removeClass(this._elementRef.nativeElement, this.variant);

        // 변형 값 저장
        this._variant = value;

        // 새 클래스 이름 추가
        this._renderer.addClass(this._elementRef.nativeElement, value);
    }
}
