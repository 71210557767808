import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr'; // Hot Module Replacement의 약자로 모듈의 변경사항을 실시간으로 어플리케이션에 반영한다.


if ( environment.production ) {
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);


if ( environment.hmr ) {
    if ( module['hot'] ) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error('webpack-dev-server에 HMR을 사용할 수 없습니다!');
        console.log('ng serve에 --hmr 플래그를 사용하고 있습니까?');
    }
} else {
    bootstrap().catch(err => console.error(err));
}
