// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';

// @ 클래스 설정 ------------------------------------------------------------------------------------------
@Injectable()
export class DreampackersSmsService {

    constructor(
        private _httpClient: HttpClient,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    

    sendSms(recipientNo, body){

        const DATA = {
            body : body,
            sendNo : '01029772514',
            recipientNo : recipientNo
        }

        const HTTPOPTIONS = {
            headers: new HttpHeaders( { 
                'Content-Type': 'application/json;charset=UTF-8'
            })
        }

        this._httpClient.post('https://4mul-api.com/toast/sms', DATA, HTTPOPTIONS)
        .subscribe((response: any) => {

        });
    
        
    }

}
