// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DreampackersSidebarModule } from '@dreampackers/components';
import { DreampackersSharedModule } from '@dreampackers/shared.module';

import { ChatPanelModule } from 'app/layout/components/chat-panel/chat-panel.module';
import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';
import { QuickPanelModule } from 'app/layout/components/quick-panel/quick-panel.module';
import { ToolbarModule } from 'app/layout/components/toolbar/toolbar.module';

import { VerticalLayout2Component } from 'app/layout/vertical/layout-2/layout-2.component';

// @ 모듈 설정 -------------------------------------------------------------------------------------------
@NgModule({
    declarations: [
        VerticalLayout2Component
    ],
    imports     : [
        RouterModule,

        DreampackersSharedModule,
        DreampackersSidebarModule,

        ChatPanelModule,
        ContentModule,
        FooterModule,
        NavbarModule,
        QuickPanelModule,
        ToolbarModule
    ],
    exports     : [
        VerticalLayout2Component
    ]
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class VerticalLayout2Module{
}
