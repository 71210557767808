// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DreampackersConfigService } from '@dreampackers/services/config.service';
import { DreampackersNavigationService } from '@dreampackers/components/navigation/navigation.service';
import { DreampackersSidebarService } from '@dreampackers/components/sidebar/sidebar.service';
import { DreampackersSplashScreenService } from '@dreampackers/services/splash-screen.service';
import { DreampackersTranslationLoaderService } from '@dreampackers/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationKorean } from 'app/navigation/i18n/kr';

// @ 데코레이터 설정 ----------------------------------------------------------------------------------------
@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class AppComponent implements OnInit, OnDestroy {

    public dreampackersConfig: any;
    public navigation: any;

    private _unsubscribeAll: Subject<any>;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _dreampackersConfigService: DreampackersConfigService,
        private _dreampackersNavigationService: DreampackersNavigationService,
        private _dreampackersSidebarService: DreampackersSidebarService,
        private _dreampackersSplashScreenService: DreampackersSplashScreenService,
        private _dreampackersTranslationLoaderService: DreampackersTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform
    ) {
        // 기본 탐색
        this.navigation = navigation;

        // 서비스 탐색을 등록하십시오
        this._dreampackersNavigationService.register('main', this.navigation);

        // 기본 탐색을 현재 탐색으로 설정
        this._dreampackersNavigationService.setCurrentNavigation('main');

        // 언어 추가
        this._translateService.addLangs(['en', 'kr']);

        // 기본 언어 설정
        this._translateService.setDefaultLang('kr');

        // 탐색 번역 설정
        this._dreampackersTranslationLoaderService.loadTranslations(navigationEnglish, navigationKorean);

        // 사용 언어
        this._translateService.use('kr');

        /**
         * ---------------------------------------------------------------------------------------------------- -
         * ngxTranslate 수정 시작
         * ---------------------------------------------------------------------------------------------------- -
         */

        /**
         * 기본 언어 이외의 언어를 사용하는 경우 (예: kr)
         * 일부 구성 요소가 실제로 존재하지 않는 문제가 발생할 수 있습니다
         * 앱이 처음 초기화 될 때 번역됩니다.
         *
         * 이것은 ngxTranslate 모듈과 관련이 있으며 아래에 임시 수정 사항이 있습니다.
         * 다국어 구현을 Angular의 핵심 언어로 옮기고 있습니다.
         **/

        // 기본 언어를 'en'으로 설정 한 다음 다시 'kr'로 설정합니다.
        // ngxTranslate가 이미 사용중인 언어로 전환되지 않으므로 '.use'를 여기서 사용할 수 없습니다.
        // 선택되었으며 강제로 실행할 방법이 없으므로 전환하여 문제를 극복합니다.
        // 기본 언어입니다.
        /**
         setTimeout (() => {
            this._translateService.setDefaultLang ( 'en');
            this._translateService.setDefaultLang ( 'tr');
         });
         */


        // 플랫폼이 모바일 인 경우 본체에 is-mobile 클래스 추가
        if ( this._platform.ANDROID || this._platform.IOS ) {
            this.document.body.classList.add('is-mobile');
        }

        // 개인 기본값 설정
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        // 페북 로그인
        this.fbLibrary();

        // 설정 변경 구독
        this._dreampackersConfigService.config
            .pipe(takeUntil(this._unsubscribeAll)) // Observable에서 데이터가 발행되기 전까지만 현재 Observable을 채택합니다.
            .subscribe((config) => {

                this.dreampackersConfig = config;

                // Boxed
                if ( this.dreampackersConfig.layout.width === 'boxed' ){
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // 색상 테마-IE11 호환성을 위해 일반 for 루프 사용
                for ( let i = 0; i < this.document.body.classList.length; i++ ) {
                    const className = this.document.body.classList[i];

                    if ( className.startsWith('theme-') ) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.dreampackersConfig.colorTheme);
            });
    }

    ngOnDestroy(): void {
        // 모든 구독에서 탈퇴
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Methods
    // -----------------------------------------------------------------------------------------------------

    toggleSidebarOpen(key): void {
        this._dreampackersSidebarService.getSidebar(key).toggleOpen();
    }

    // 페이스북 로그인
    fbLibrary() {
 
        (window as any).fbAsyncInit = function() {
          window['FB'].init({
            appId      : '534306157197085', // %%MODIFY%%
            cookie     : true,
            xfbml      : true,
            version    : 'v6.0'
          });
          window['FB'].AppEvents.logPageView();
        };
     
        (function(d, s, id){
           var js, fjs = d.getElementsByTagName(s)[0];
           if (d.getElementById(id)) {return;}
           js = d.createElement(s); js.id = id;
           js.src = "https://connect.facebook.net/en_US/sdk.js";
           fjs.parentNode.insertBefore(js, fjs);
         }(document, 'script', 'facebook-jssdk'));
    }
}
