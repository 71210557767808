// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { DreampackersConfigService } from '@dreampackers/services/config.service';
import { DreampackersSidebarService } from '@dreampackers/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';

import { AuthenticationService } from '@dreampackers/authentications/authentication.service';
import { LoginService } from 'app/main/pages/authentication/login/login.service';

// @ 데코레이터 설정 ----------------------------------------------------------------------------------------
@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class ToolbarComponent implements OnInit, OnDestroy{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    member: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _dreampackersConfigService: DreampackersConfigService,
        private _dreampackersSidebarService: DreampackersSidebarService,
        private _translateService: TranslateService,
        private _authenticationService: AuthenticationService,
        private _loginService: LoginService

    ){
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'kr',
                title: '한국어',
                flag : 'kr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.member = {
            name : 'default',
            sns_profile : 'http://placehold.it/100x100',
            sns_id : '0000000000'
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle 
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void{

        const lsCheck = localStorage.getItem('member');
        if(lsCheck) {
            this.member = JSON.parse(localStorage.getItem('member'));
        }


        // 변경 사항에 대한 데이터를 업데이트하기 위한 구독
        // this._loginService.onCurrentMemberDataChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(member => {
        //         if(member){
        //             this.member = member;
        //          }
        //     });

                
        // 구성 변경 사항 구독
        this._dreampackersConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // 선택한 언어를 기본 언어에서 설정
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
    }

    ngOnDestroy(): void{
        // 모든 구독에서 탈퇴
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Methods
    // -----------------------------------------------------------------------------------------------------


    toggleSidebarOpen(key): void{
        this._dreampackersSidebarService.getSidebar(key).toggleOpen();
    }

    search(value): void{
        // 여기서 검색하십시오...
    }

    setLanguage(lang): void{
        // 툴바에 대해 선택된 언어 설정
        this.selectedLanguage = lang;

        // 선택한 언어를 번역에 사용
        this._translateService.use(lang.id);
    }

    logout() {
        this._authenticationService.logout(true);
    }
}
