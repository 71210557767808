import { NgModule } from '@angular/core';

import { DreampackersHighlightComponent } from '@dreampackers/components/highlight/highlight.component';

@NgModule({
    declarations: [
        DreampackersHighlightComponent
    ],
    exports: [
        DreampackersHighlightComponent
    ],
})
export class DreampackersHighlightModule
{
}
