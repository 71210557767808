export class HospitalFakeDb
{
    
    public static filters = [
        {
            'id'    : '0',
            'handle': 'starred',
            'title' : '중요',
            'icon'  : 'star'
        },
        {
            'id'    : '1',
            'handle': 'important',
            'title' : '긴급',
            'icon'  : 'error'
        },
        {
            'id'    : '2',
            'handle': 'completed',
            'title' : '완료',
            'icon'  : 'monetization_on'
        },
        {
            'id'    : '3',
            'handle': 'deleted',
            'title' : '삭제',
            'icon'  : 'delete'
        }
    ];

    public static tags = [
        {
            'id'    : 0,
            'handle': '0',
            'title' : '비활성화',
            'color' : '#F44336'
        },
        {
            'id'    : 1,
            'handle': '1',
            'title' : '활성화',
            'color' : '#388E3C'
        }
    ];

    public static categories = [
        {
            'id'    : '01',
            'handle': 'Seoul',
            'title' : '서울특별시',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '02',
            'handle': 'Busan',
            'title' : '부산광역시',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '03',
            'handle': 'Daegu',
            'title' : '대구광역시',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '04',
            'handle': 'Incheon',
            'title' : '인천광역시',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '05',
            'handle': 'Gwangju',
            'title' : '광주광역시',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '06',
            'handle': 'Daejeon',
            'title' : '대전광역시',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '07',
            'handle': 'Ulsan',
            'title' : '울산광역시',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '08',
            'handle': 'Sejong',
            'title' : '세종특별자치시',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '09',
            'handle': 'Gyeonggi-do',
            'title' : '경기도',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '10',
            'handle': 'Gangwon-do',
            'title' : '강원도',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '11',
            'handle': 'Chungcheongbuk-do',
            'title' : '충청북도',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '12',
            'handle': 'Chungcheongnam-do',
            'title' : '충청남도',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '13',
            'handle': 'Jeollabuk-do',
            'title' : '전라북도',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '14',
            'handle': 'Jeollanam-do',
            'title' : '전라남도',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '15',
            'handle': 'Gyeongsangbuk-do',
            'title' : '경상북도',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '16',
            'handle': 'Gyeongsangnam-do',
            'title' : '경상남도',
            'icon'  : 'place',
            'color' : '#468ABB'
        },
        {
            'id'    : '17',
            'handle': 'Jeju-do',
            'title' : '제주특별자치도',
            'icon'  : 'place',
            'color' : '#468ABB'
        }
    ];

}
