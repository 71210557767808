import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { DreampackersConfigService } from '@dreampackers/services/config.service';
import { DreampackersNavigationService } from '@dreampackers/components/navigation/navigation.service';
import { DreampackersPerfectScrollbarDirective } from '@dreampackers/directives/dreampackers-perfect-scrollbar/dreampackers-perfect-scrollbar.directive';
import { DreampackersSidebarService } from '@dreampackers/components/sidebar/sidebar.service';

import { LoginService } from 'app/main/pages/authentication/login/login.service';

@Component({
    selector     : 'navbar-vertical-style-1',
    templateUrl  : './style-1.component.html',
    styleUrls    : ['./style-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
    dreampackersConfig: any;
    navigation: any;
    member: any;

    // Private
    private _dreampackersPerfectScrollbar: DreampackersPerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _dreampackersConfigService: DreampackersConfigService,
        private _dreampackersNavigationService: DreampackersNavigationService,
        private _dreampackersSidebarService: DreampackersSidebarService,
        private _router: Router,
        private _loginService: LoginService

    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();


        this.member = {
            name : 'default',
            sns_profile : 'https://via.placeholder.com/100x100',
            sns_id : '0000000000'
        }
        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(DreampackersPerfectScrollbarDirective, {static: true})
    set directive(theDirective: DreampackersPerfectScrollbarDirective) {
        if ( !theDirective )
        {
            return;
        }

        this._dreampackersPerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._dreampackersNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._dreampackersPerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        this._dreampackersPerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                    });
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {


        const lsCheck = localStorage.getItem('member');
        if(lsCheck) {
            this.member = JSON.parse(localStorage.getItem('member'));
        }


         // 변경 사항에 대한 데이터를 업데이트하기 위한 구독
        //  this._loginService.onCurrentMemberDataChanged
        //  .pipe(takeUntil(this._unsubscribeAll))
        //  .subscribe(member => {
        //      if(member){
        //         this.member = member;
        //      }
        //   });
 

        
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._dreampackersSidebarService.getSidebar('navbar') )
                    {
                        this._dreampackersSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Subscribe to the config changes
        this._dreampackersConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.dreampackersConfig = config;
            });

        // Get current navigation
        this._dreampackersNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._dreampackersNavigationService.getCurrentNavigation();
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    toggleSidebarOpened(): void {
        this._dreampackersSidebarService.getSidebar('navbar').toggleOpen();
    }

    toggleSidebarFolded(): void {
        this._dreampackersSidebarService.getSidebar('navbar').toggleFold();
    }
}
