// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '@dreampackers/authentications/authentication.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DreampackersSmsService } from '@dreampackers/services/sms.service';
import { DreampackersKakaomsgService } from '@dreampackers/services/kakaomsg.service';


// @ 클래스 설정 ------------------------------------------------------------------------------------------
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private _authenticationService: AuthenticationService,
        private _router: Router,
        private _location: Location,
        private _matSnackBar: MatSnackBar,
        private _dreampackersSmsService: DreampackersSmsService,
        private _dreampackersKakaomsgService: DreampackersKakaomsgService

        ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Methods
    // -----------------------------------------------------------------------------------------------------

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                console.error(err);

                // Kakao 발송 - 개발자 (수신번호, 제목, 템플릿 코드, 파라미터, 내용, 예약일시)
                this._dreampackersKakaomsgService.sendKakao('01029772514', '[사물 401 ERROR]' , 'error', {'에러코드':'대시보드 - 401', '에러내용' : JSON.stringify(err)}, '', '');

                location.reload();

                this._authenticationService.logout(false);
                
            } else if (err.status === 403) {
                console.error(err);

                this._matSnackBar.open('접근 권한이 만료되었습니다.', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
                
                this._router.navigate(['pages/auth/login']);

                // Kakao 발송 - 개발자 (수신번호, 제목, 템플릿 코드, 파라미터, 내용, 예약일시)
                this._dreampackersKakaomsgService.sendKakao('01029772514', '[사물 403 ERROR]' , 'error', {'에러코드':'대시보드 - 403', '에러내용' : JSON.stringify(err)}, '', '');

            } else if (err.status === 500) {
                console.error(err);

                this._router.navigate(['pages/errors/error-500']);

                // Kakao 발송 - 개발자 (수신번호, 제목, 템플릿 코드, 파라미터, 내용, 예약일시)
                this._dreampackersKakaomsgService.sendKakao('01029772514', '[사물 500 ERROR]' , 'error', {'에러코드':'대시보드 - 500', '에러내용' : JSON.stringify(err)}, '', '');

            } 

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}