// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';

// @ 클래스 설정 ------------------------------------------------------------------------------------------
@Injectable()
export class DreampackersKakaomsgService {

    constructor(
        private _httpClient: HttpClient,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    

    sendKakao(recipientNo, title, templateCode, templateParameter, content, requestDate){

        // 카카오 메세지 서비스 테스트 시작
        const DATA = {
            templateCode: templateCode,
            content: this.replaceAll(content,'<br />',''),
            templateTitle: title,
            recipientNo: recipientNo,
            requestDate: requestDate,
            templateParameter: templateParameter
        }

        const HTTPOPTIONS = {
            headers: new HttpHeaders( { 
                'Content-Type': 'application/json;charset=UTF-8'
            })
        }

        this._httpClient.post('https://4mul-api.com/toast/kakao', DATA, HTTPOPTIONS)
        .subscribe((response: any) => {

        });
    
        // SMS 서비스 테스트 끝
        
    }

    replaceAll(str, searchStr, replaceStr) {
        return str.split(searchStr).join(replaceStr);
      }
      

}
