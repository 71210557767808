// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Component } from '@angular/core';

// @ 데코레이터 설정 ---------------------------------------------------------------------------------------
@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class FooterComponent{
    constructor(){
    }
}
