<div class="navbar-top" [ngClass]="dreampackersConfig.layout.navbar.secondaryBackground">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/logo.svg">
        <span class="logo-text secondary-text">사물 관리자</span>
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" [ngClass]="dreampackersConfig.layout.navbar.primaryBackground"
     dreampackersPerfectScrollbar [dreampackersPerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column" [ngClass]="dreampackersConfig.layout.navbar.secondaryBackground">

        <div class="h3 username">{{member.name}}</div>
        <div class="h5 email hint-text mt-8">{{member.sns_id}}</div>
        <div class="avatar-container" [ngClass]="dreampackersConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="{{member.sns_profile}}">
        </div>

    </div>

    <div class="navbar-content">
        <dreampackers-navigation class="material2" layout="vertical"></dreampackers-navigation>
    </div>

</div>
