// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

// @ 클래스 설정 ------------------------------------------------------------------------------------------
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    currentMember: any;

    // Private

    constructor (
        
        ) { 

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Methods
    // -----------------------------------------------------------------------------------------------------

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = localStorage.getItem('token');
        const isLoggedIn = token;
        if (isLoggedIn) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }
}