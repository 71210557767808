// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Dreampackers_CONFIG } from '@dreampackers/services/config.service';

// @ 모듈 설정 --------------------------------------------------------------------------------------------
@NgModule()
export class DreampackersModule{
    constructor(@Optional() @SkipSelf() parentModule: DreampackersModule) {
        if ( parentModule ){
            throw new Error('DreampackersModule이 이미 로드되었습니다. AppModule에서만 가져옵니다!');
        }
    }

    static forRoot(config): ModuleWithProviders<DreampackersModule>{
        return {
            ngModule : DreampackersModule,
            providers: [
                {
                    provide : Dreampackers_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
