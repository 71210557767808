// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders} from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

// @ 클래스 설정 ------------------------------------------------------------------------------------------
@Injectable()
export class LoginService implements Resolve<any> {
    
    SERVER_URL: string = 'https://4mul-api.com'; // *** SERVER IP 수정 ***
    TOKEN_API_URL: string = '/memberToken/' // *** API URL 수정 ***
    GRADE_API_URL: string = '/memberGrade/' // *** API URL 수정 ***
    MEMBER_API_URL: string = '/member/' // *** API URL 수정 ***

    routeParams: any;
    member: any;

    public onCurrentMemberDataChanged: BehaviorSubject<any>;


    constructor(
        private _httpClient: HttpClient
    ) {
        this.onCurrentMemberDataChanged = new BehaviorSubject({});
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([

            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getToken(data): Promise<any> {

        return new Promise((resolve, reject) => {
                this._httpClient.post(this.SERVER_URL + this.TOKEN_API_URL, data  )
                    .subscribe((response: any) => {

                        if(response.result === 'ok'){
                            this.member = response.res[0];

                            localStorage.setItem('token',response.token)
                            localStorage.setItem('member',JSON.stringify(this.member))

                            this.onCurrentMemberDataChanged.next(this.member);
                        }
                        resolve(response);
                    }, reject);
            }
        );
    }

    getGrade(data): Promise<any> {

        return new Promise((resolve, reject) => {
                this._httpClient.post(this.SERVER_URL + this.GRADE_API_URL, data  )
                    .subscribe((response: any) => {
                        this.member = response.res[0];
                        this.onCurrentMemberDataChanged.next(this.member);
                        resolve(response);
                    }, reject);
            }
        );
    }

    addMember(member): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.SERVER_URL + this.MEMBER_API_URL, member  )
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

}
