// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { DreampackersConfig } from '@dreampackers/types';

/**
 * 기본 Dreampackers 구성
 *
 * 이 옵션을 편집하여 기본 옵션을 변경할 수 있습니다. 이 모든 옵션들도
 * 구성 요소별로 변경되었습니다. `app / main / pages / authentication / login / login.component.ts`를 참조하십시오
 * 구성 요소별로 이러한 옵션을 변경하는 방법에 대한 자세한 내용은 생성자 메서드를 참조하십시오.
 */

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export const dreampackersConfig: DreampackersConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme      : 'theme-default',
    customScrollbars: true,
    layout          : {
        style    : 'vertical-layout-1',
        width    : 'fullwidth',
        navbar   : {
            primaryBackground  : 'dreampackers-navy-700',
            secondaryBackground: 'dreampackers-navy-900',
            folded             : false,
            hidden             : false,
            position           : 'left',
            variant            : 'vertical-style-1'
        },
        toolbar  : {
            customBackgroundColor: false,
            background           : 'dreampackers-white-500',
            hidden               : false,
            position             : 'below-static'
        },
        footer   : {
            customBackgroundColor: true,
            background           : 'dreampackers-navy-900',
            hidden               : false,
            position             : 'below-fixed'
        },
        sidepanel: {
            hidden  : true,
            position: 'right'
        }
    }
};
