// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { Component, ViewEncapsulation } from '@angular/core';

// @ 데코레이터 설정 ----------------------------------------------------------------------------------------
@Component({
    selector     : 'content',
    templateUrl  : './content.component.html',
    styleUrls    : ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class ContentComponent{
    constructor(){
    }
}
