// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { DreampackersSearchBarModule, DreampackersShortcutsModule } from '@dreampackers/components';
import { DreampackersSharedModule } from '@dreampackers/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { LoginService } from 'app/main/pages/authentication/login/login.service';

// @ 모듈 설정 -------------------------------------------------------------------------------------------
@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,

        DreampackersSharedModule,
        DreampackersSearchBarModule,
        DreampackersShortcutsModule
    ],
    providers      : [
        LoginService
    ],
    exports     : [
        ToolbarComponent
    ]
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class ToolbarModule{
}
