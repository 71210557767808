import { NgModule } from '@angular/core';

import { DreampackersIfOnDomDirective } from '@dreampackers/directives/dreampackers-if-on-dom/dreampackers-if-on-dom.directive';
import { DreampackersInnerScrollDirective } from '@dreampackers/directives/dreampackers-inner-scroll/dreampackers-inner-scroll.directive';
import { DreampackersPerfectScrollbarDirective } from '@dreampackers/directives/dreampackers-perfect-scrollbar/dreampackers-perfect-scrollbar.directive';
import { DreampackersMatSidenavHelperDirective, DreampackersMatSidenavTogglerDirective } from '@dreampackers/directives/dreampackers-mat-sidenav/dreampackers-mat-sidenav.directive';

@NgModule({
    declarations: [
        DreampackersIfOnDomDirective,
        DreampackersInnerScrollDirective,
        DreampackersMatSidenavHelperDirective,
        DreampackersMatSidenavTogglerDirective,
        DreampackersPerfectScrollbarDirective
    ],
    imports     : [],
    exports     : [
        DreampackersIfOnDomDirective,
        DreampackersInnerScrollDirective,
        DreampackersMatSidenavHelperDirective,
        DreampackersMatSidenavTogglerDirective,
        DreampackersPerfectScrollbarDirective
    ]
})
export class DreampackersDirectivesModule
{
}
