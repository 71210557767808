/**
 * 이 파일에는 Angular에 필요한 폴리필이 포함되어 있으며 앱 전에 로드됩니다.
 * 이 파일에 추가 폴리 필을 추가 ​​할 수 있습니다.
 *
 * 이 파일은 두 섹션으로 나뉩니다.
 * 1. 브라우저 폴리 필. 이들은 ZoneJS를로드하기 전에 적용되며 브라우저별로 정렬됩니다.
 * 2. 응용 프로그램 가져 오기. ZoneJS 이후에 가져온 파일로 메인 파일보다 먼저로드해야합니다
 * 파일.
 *
 * 현재 설정은 소위 "evergreen"브라우저 용입니다. 브라우저의 마지막 버전
 * 자동으로 업데이트됩니다. 여기에는 Safari> = 10, Chrome> = 55 (오페라 포함),
 * 데스크톱의 경우 Edge> = 13, 모바일의 경우 iOS 10 및 Chrome
 *
 * https://angular.io/guide/browser-support에서 자세히 알아보십시오.
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** SVG 요소에서 NgClass를 지원하려면 IE10 및 IE11에 다음이 필요합니다. * /
// import 'classlist.js'; //`npm install --save classlist.js`를 실행하십시오.

/**
 * 웹 애니메이션`@ angular / platform-browser / animations`
 * AnimationBuilder를 응용 프로그램 내에서 사용하고 IE / Edge 또는 Safari를 사용하는 경우에만 필요합니다.
 * Angular에서 표준 애니메이션 지원은 폴리 필을 필요로하지 않습니다 (Angular 6.0 기준).
 */

import 'web-animations-js';  // `npm install --save web-animations-js` 실행.

/**
 * 기본적으로 zone.js는 가능한 모든 macroTask 및 DomEvents를 패치합니다.
 * 사용자는 다음 플래그를 설정하여 macroTask / DomEvents 패치의 일부를 비활성화 할 수 있습니다
 * `zone.js`가로드되기 전에 이러한 플래그를 설정해야하며, 웹팩
 * 번들 상단에 가져 오기를하므로 사용자가 별도의 파일을 만들어야합니다
 * 이 디렉토리 (예 : zone-flags.ts)에 다음 플래그를 넣습니다.
 * 해당 파일에 넣은 다음 zone.js를 가져 오기 전에 다음 코드를 추가하십시오.
 * 수입 './zone-flags.ts';
 *
 * zone-flags.ts에서 허용되는 플래그가 여기에 나열됩니다.
 *
 * 다음 플래그는 모든 브라우저에서 작동합니다.
 *
 * (모든 창) .__ Zone_disable_requestAnimationFrame = true; // 패치 requestAnimationFrame 비활성화
 * (모든 창) .__ Zone_disable_on_property = true; // onclick과 같은 패치 onProperty를 비활성화합니다.
 * (있는 창) .__ zone_symbol__UNPATCHED_EVENTS = [ '스크롤', 'mousemove']; // 패치 지정 eventNames 비활성화
 *
 * IE / Edge 개발자 도구에서 addEventListener도 zone.js로 래핑됩니다.
 * 다음 플래그를 사용하면 IE / Edge에 대한`zone.js '패치를 무시합니다.
 *
 * (모든 창) .__ Zone_enable_cross_context_check = true;
 *
 */

/***************************************************************************************************
 * Angular 자체에는 Zone JS가 기본적으로 필요합니다.
 */
import 'zone.js/dist/zone';  // Angular CLI에 포함되어 있습니다.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

// 윈도우 자체에 값을 할당하여 윈도우에 전역을 추가하십시오.
(window as any).global = window;

// Uncaught ReferenceError: _rollupMoment__default is not defined  오류 해결
window['_rollupMoment__default'] = null;
