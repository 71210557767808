// %%MODIFY%%
export const locale = {
    lang: 'kr',
    data: {
        'NAV': {
            'COMPENSATION' : '보상 관리',
            'HOSPITAL'     : '병원 관리',
            'EXPERT'       : '전문가 관리',
            'MEMBER'       : '회원 관리',
            'REVIEW'       : '후기 관리',
            'NOTICE'       : '공지사항 관리',
            'FAQ'          : 'FAQ 관리',
            'BASIC_SETUP'  : '앱정보 관리',
            'CALCULATOR'   : '합의금계산 관리',
            'REG_COMPENSATION' : '보상 등록',

            'CHANGELOG'    : '변경기록',

            'APPLICATIONS' : '관리',
            'PAGES'        : '페이지',
            'DOCUMENTATION': '문서'
        }
    }
};
