// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DreampackersSharedModule } from '@dreampackers/shared.module';

import { ContentComponent } from 'app/layout/components/content/content.component';

// @ 모듈 설정 -------------------------------------------------------------------------------------------
@NgModule({
    declarations: [
        ContentComponent
    ],
    imports     : [
        RouterModule,
        DreampackersSharedModule
    ],
    exports     : [
        ContentComponent
    ]
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class ContentModule{
}
