import { NgModule } from '@angular/core';

import { DreampackersCountdownComponent } from '@dreampackers/components/countdown/countdown.component';

@NgModule({
    declarations: [
        DreampackersCountdownComponent
    ],
    exports: [
        DreampackersCountdownComponent
    ],
})
export class DreampackersCountdownModule
{
}
