// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { NgModule } from '@angular/core';

import { DreampackersSharedModule } from '@dreampackers/shared.module';

import { NavbarComponent } from 'app/layout/components/navbar/navbar.component';
import { NavbarHorizontalStyle1Module } from 'app/layout/components/navbar/horizontal/style-1/style-1.module';
import { NavbarVerticalStyle1Module } from 'app/layout/components/navbar/vertical/style-1/style-1.module';
import { NavbarVerticalStyle2Module } from 'app/layout/components/navbar/vertical/style-2/style-2.module';

// @ 모듈 설정 -------------------------------------------------------------------------------------------
@NgModule({
    declarations: [
        NavbarComponent
    ],
    imports     : [
        DreampackersSharedModule,

        NavbarHorizontalStyle1Module,
        NavbarVerticalStyle1Module,
        NavbarVerticalStyle2Module
    ],
    exports     : [
        NavbarComponent
    ]
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class NavbarModule{
}
