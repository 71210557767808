import { Component } from '@angular/core';

@Component({
    selector   : 'dreampackers-demo-content',
    templateUrl: './demo-content.component.html',
    styleUrls  : ['./demo-content.component.scss']
})
export class DreampackersDemoContentComponent
{
    /**
     * Constructor
     */
    constructor()
    {
    }
}
