import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { DreampackersConfigService } from '@dreampackers/services/config.service';
import { DreampackersNavigationService } from '@dreampackers/components/navigation/navigation.service';
import { DreampackersPerfectScrollbarDirective } from '@dreampackers/directives/dreampackers-perfect-scrollbar/dreampackers-perfect-scrollbar.directive';
import { DreampackersSidebarService } from '@dreampackers/components/sidebar/sidebar.service';

@Component({
    selector     : 'navbar-vertical-style-2',
    templateUrl  : './style-2.component.html',
    styleUrls    : ['./style-2.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarVerticalStyle2Component implements OnInit, OnDestroy
{
    dreampackersConfig: any;
    navigation: any;

    // Private
    private _dreampackersPerfectScrollbar: DreampackersPerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DreampackersConfigService} _dreampackersConfigService
     * @param {DreampackersNavigationService} _dreampackersNavigationService
     * @param {DreampackersSidebarService} _dreampackersSidebarService
     * @param {Router} _router
     */
    constructor(
        private _dreampackersConfigService: DreampackersConfigService,
        private _dreampackersNavigationService: DreampackersNavigationService,
        private _dreampackersSidebarService: DreampackersSidebarService,
        private _router: Router
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(DreampackersPerfectScrollbarDirective, {static: true})
    set directive(theDirective: DreampackersPerfectScrollbarDirective)
    {
        if ( !theDirective )
        {
            return;
        }

        this._dreampackersPerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._dreampackersNavigationService.onItemCollapseToggled
            .pipe(
                delay(500),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this._dreampackersPerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                    setTimeout(() => {
                        this._dreampackersPerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
                    });
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if ( this._dreampackersSidebarService.getSidebar('navbar') )
                    {
                        this._dreampackersSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Get current navigation
        this._dreampackersNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._dreampackersNavigationService.getCurrentNavigation();
            });

        // Subscribe to the config changes
        this._dreampackersConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.dreampackersConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void
    {
        this._dreampackersSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void
    {
        this._dreampackersSidebarService.getSidebar('navbar').toggleFold();
    }
}
