// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { MatPaginatorModule } from '@angular/material/paginator';

import { JwtInterceptor } from '../@dreampackers/authentications/jwt.interceptor';
import { ErrorInterceptor } from '../@dreampackers/authentications/error.interceptor';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { DreampackersModule } from '@dreampackers/dreampackers.module';
import { DreampackersSharedModule } from '@dreampackers/shared.module';
import { DreampackersProgressBarModule, DreampackersSidebarModule, DreampackersThemeOptionsModule } from '@dreampackers/components';

import { dreampackersConfig } from 'app/dreampackers-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';



// @ 경로 설정  ------------------------------------------------------------------------------------------
const appRoutes: Routes = [
    {
        path        : 'apps',
        loadChildren: () => import('./main/apps/apps.module').then(m => m.AppsModule)
    },
    {
        path        : 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path        : 'kakao-redirect',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path        : 'kakao-redirect-signup',
        loadChildren: () => import('./main/pages/authentication/kakao-redirect-signup/kakao-redirect-signup.module').then(m => m.KakaoRedirectSignupModule)
    },
    {
        path        : 'documentation',
        loadChildren: () => import('./main/documentation/documentation.module').then(m => m.DocumentationModule)
    },
    // {
    //     path      : '',
    //     redirectTo: '/pages/maintenance', // 유지보수 상태 (일반 상태 주석처리)
    //     pathMatch: 'full'
    // },
    {
        path      : '',
        redirectTo: '/pages/auth/login', // 일반 상태 (유지보수 상태 주석처리)
        pathMatch: 'full'
    },
    {
        path      : '**',
        redirectTo: '/pages/errors/error-404', // 경로에 없는 URL 접속시 404 페이지로 이동
        pathMatch: 'full'
    }
];

// @ 모듈 설정  ------------------------------------------------------------------------------------------
@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        // 매트리얼 모먼트 모듈
        MatMomentDateModule,

        // 매트리얼 모듈
        MatButtonModule,
        MatIconModule,
        MatPaginatorModule,
        
        // Dreampackers 모듈
        DreampackersModule.forRoot(dreampackersConfig),
        DreampackersProgressBarModule,
        DreampackersSharedModule,
        DreampackersSidebarModule,
        DreampackersThemeOptionsModule,

        // 앱 모듈
        LayoutModule,
        AppStoreModule
    ],
    bootstrap   : [
        AppComponent
    ],
    providers      : [
        // 보안, 에러 인터셉터
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ]
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class AppModule{
}
