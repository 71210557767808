// @ 기본 임포트 ------------------------------------------------------------------------------------------
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DreampackersDirectivesModule } from '@dreampackers/directives/directives';
import { DreampackersPipesModule } from '@dreampackers/pipes/pipes.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

// @ 서비스 임포트 ------------------------------------------------------------------------------------------
import { JwtHelperService } from '@auth0/angular-jwt';
import { DreampackersSmsService } from '@dreampackers/services/sms.service';
import { DreampackersKakaomsgService } from '@dreampackers/services/kakaomsg.service';

// @ 모듈 설정 -------------------------------------------------------------------------------------------
@NgModule({
    imports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,

        DreampackersDirectivesModule,
        DreampackersPipesModule,
        MatSnackBarModule
    ],
    exports  : [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,

        DreampackersDirectivesModule,
        DreampackersPipesModule
    ],
    providers: [
        JwtHelperService,
        DreampackersSmsService,
        DreampackersKakaomsgService
    ]
})

// @ 클래스 설정 ------------------------------------------------------------------------------------------
export class DreampackersSharedModule{
}
