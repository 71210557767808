// @ 클래스 설정 ------------------------------------------------------------------------------------------
import { DreampackersNavigation } from '@dreampackers/types';

// %%MODIFY%%
export const navigation: DreampackersNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'compensation',
                title    : '보상관리',
                translate: 'NAV.COMPENSATION',
                type     : 'item',
                icon     : 'brightness_low',
                url      : '/apps/compensation',
                // badge    : {
                //     title: '3',
                //     bg   : '#FF6F00',
                //     fg   : '#FFFFFF'
                // }
            },
            {
                id       : 'hospital',
                title    : '병원 관리',
                translate: 'NAV.HOSPITAL',
                type     : 'collapsable',
                icon     : 'local_hospital',
                children : [
                            {
                                id   : 'hospital',
                                title: '병원 목록',
                                type : 'item',
                                url  : '/apps/hospital-new/datum'
                            },
                            {
                                id   : 'hospital_check',
                                title: '오프라인 병원 목록',
                                type : 'item',
                                url  : '/apps/hospital-check/datum'
                            }
                        ]
            },
            {
                id       : 'expert',
                title    : '전문가 관리',
                translate: 'NAV.EXPERT',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/experts/experts'
            },
            {
                id       : 'member',
                title    : '회원 관리',
                translate: 'NAV.MEMBER',
                type     : 'item',
                icon     : 'group',
                url      : '/apps/members-new/datum'
            },
            {
                id       : 'review',
                title    : '후기 관리',
                translate: 'NAV.REVIEW',
                type     : 'item',
                icon     : 'star_half',
                url      : '/apps/review/datum'
            },
            {
                id       : 'notice',
                title    : '공지사항 관리',
                translate: 'NAV.NOTICE',
                type     : 'item',
                icon     : 'notification_important',
                url      : '/apps/notice/datum'
            },
            {
                id       : 'faq',
                title    : 'FAQ 관리',
                translate: 'NAV.FAQ',
                type     : 'item',
                icon     : 'question_answer',
                url      : '/apps/faq/datum'
            },
            {
                id       : 'basic_setup',
                title    : '앱정보 관리',
                translate: 'NAV.BASIC_SETUP',
                type     : 'item',
                icon     : 'info',
                url      : '/apps/basic_setup/data'
            },
            {
                id       : 'calculator',
                title    : '합의금 계산 결과 관리',
                translate: 'NAV.CALCULATOR',
                type     : 'item',
                icon     : 'assignment_turned_in',
                url      : '/apps/calculator/datum'
            },
            {
                id       : 'reg_compensation',
                title    : '보상 등록',
                translate: 'NAV.REG_COMPENSATION',
                type     : 'item',
                icon     : 'note_add',
                url      : '/apps/reg_compensation/data'
            },
            // 고도화 단계 예정
            // {
            //     id       : 'dashboards',
            //     title    : 'Dashboards',
            //     translate: 'NAV.DASHBOARDS',
            //     type     : 'collapsable',
            //     icon     : 'dashboard',
            //     children : [
            //         {
            //             id   : 'analytics',
            //             title: 'Analytics',
            //             type : 'item',
            //             url  : '/apps/dashboards/analytics'
            //         },
            //         {
            //             id   : 'project',
            //             title: 'Project',
            //             type : 'item',
            //             url  : '/apps/dashboards/project'
            //         }
            //     ]
            // },
            // {
            //     id       : 'calendar',
            //     title    : 'Calendar',
            //     translate: 'NAV.CALENDAR',
            //     type     : 'item',
            //     icon     : 'today',
            //     url      : '/apps/calendar'
            // },
            // {
            //     id       : 'academy',
            //     title    : 'Academy',
            //     translate: 'NAV.ACADEMY',
            //     type     : 'item',
            //     icon     : 'school',
            //     url      : '/apps/academy'
            // }
        ]
    },
    {
        id      : 'documentation',
        title   : 'Documentation',
        translate: 'NAV.DOCUMENTATION',
        icon    : 'import_contacts',
        type    : 'group',
        children: [
            {
                id   : 'changelog',
                title: 'Changelog',
                translate: 'NAV.CHANGELOG',
                type : 'item',
                icon : 'update',
                url  : '/documentation/changelog',
                // badge: {
                //     title: '1.0.0',
                //     bg   : '#468abb',
                //     fg   : '#FFFFFF'
                // }
            },
            
        ]
    }
];
